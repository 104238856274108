<template>
  <div class="card">
    <div class="section">{{ serial }}: {{ states.length }}</div>
    <div class="container" style="width: 100%">
      <div class="row">
        <div class="buttons col-sm" v-if="states">
          <div v-for="(state, index) in this.states" :key="index">
            Button: {{ index }}: <span v-if="state == '0'">🔴</span><span v-else>🟢</span>
          </div>
        </div>
        <div class="buttons col-sm" v-if="false">
          <div v-for="(state, index) in this.states" :key="index">
            Button: {{ index }}: <span v-if="state == '0'">🔴</span><span v-else>🟢</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'BuzzerState',
  props: {
    states: Array,
    serial: String
  },
  data: () => {
    return {}
  },
  created () {
    console.log('States: ', this.states)
  }
}
</script>

<style scoped>

</style>
