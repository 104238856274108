<template>
  <div class="mqtt container">
    <div class="row">
      <input v-model="username" placeholder="Username">
      <input v-model="password" placeholder="Password">
      <input v-model="host" placeholder="Username">
      <button @click="submit" value="Connect">Connect</button>
    </div>
    <div class="row">
      <BuzzerState v-for="(states, serial) in buzzers" :key="serial" :states="states" :serial="serial" ></BuzzerState>
    </div>
  </div>
</template>

<script>
import BuzzerState from './BuzzerState.vue'

import * as mqtt from 'mqtt'
import Vue from 'vue'

export default {
  name: 'MQTTBox',
  components: {
    BuzzerState
  },
  data: () => {
    return {
      username: '',
      password: '',
      host: 'wss://buzz.moospirit.org/mqtt',
      client: null,
      msg: '',
      buzzers: {}
    }
  },
  methods: {
    receiveMsg: function (topic, message) {
      const decodeMsg = message.toString()
      console.log('Decoded: ', decodeMsg)
      if (topic === 'buzz/ping') {
        if (decodeMsg in this.buzzers) {
          return
        }
        Vue.set(this.buzzers, decodeMsg, [])
      }
      const splitTopic = topic.split('/')
      if (splitTopic.length === 3) {
        if (!(splitTopic[1] in this.buzzers)) {
          console.log('Unknow buzzer')
          Vue.set(this.buzzers, splitTopic[1], [])
        }
        if (splitTopic[2] === 'general_events') {
          const payload = JSON.parse(decodeMsg)
          for (let i = 0; i < payload.input_state.length; i++) {
            Vue.set(this.buzzers[splitTopic[1]], i, payload.input_state[i])
          }
          console.log(this.buzzers[splitTopic[1]])
        }
      }
      // console.log("Received: ", message, topic)
      this.msg += message + '\n'
    },
    submit: function () {
      console.log('User: ', this.username, ' password: ', this.password)

      const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)

      const options = {
        keepalive: 60,
        clientId: clientId,
        protocolId: 'MQTT',
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 1000,
        connectTimeout: 30 * 1000,
        username: this.username,
        password: this.password
      }

      console.log('Connecting mqtt client')
      this.client = mqtt.connect(this.host, options)

      this.client.on('error', (err) => {
        console.log('Connection error: ', err)
        this.client.end()
      })

      this.client.on('reconnect', () => {
        console.log('Reconnecting...')
      })
      this.client.on('connect', () => {
        console.log('Client connected:' + clientId)
        // Subscribe
        this.client.subscribe('buzz/ping')
        this.client.subscribe('buzz/+/general_events')
      })
      this.client.on('message', this.receiveMsg)
      /* (topic, message) => {
        console.log('Received Message: ' + message.toString() + '\nOn topic: ' + topic)
        this.receiveMsg(message);
      }) */
    }
  }
}
</script>

<style scoped>

</style>
